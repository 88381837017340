// -- IMPORTS --
import React from 'react'
import PropTypes from 'prop-types'

// mui components
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

// mui icons
import MailOutlineIcon from '@mui/icons-material/MailOutline'

// -- EXPORTED REACT COMPONENT --
const EmailField = (props) => {
  // -- PROPS --
  const {value, setValue, autoFocus, fullWidth, disabled, onKeyUp, fieldId} = props

  // -- RETURN STATEMENT --
  return (
    <TextField
      size={'small'}
      label={'Email'}
      value={value}
      onChange={(e) => { setValue(e.target.value) }}
      type={'email'}
      autoFocus={autoFocus ? true : false}
      fullWidth={fullWidth ? true : false}
      disabled={disabled ? true : false}
      id={fieldId ? fieldId : 'email'}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} >
            <IconButton size={'small'} disabled >
              <MailOutlineIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      onKeyUp={onKeyUp ? onKeyUp : () => {}}
    />
  )
}

EmailField.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyUp: PropTypes.func,
  fieldId: PropTypes.string
}

export default EmailField